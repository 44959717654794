<template>
  <b-row>
    <b-col cols="12">
      <b-card-code
        title="Edit Corporate"
      >
        <validation-observer ref="corporateEdit">
          <b-form @submit.prevent="editCorporate()">
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Corporate Name"
                  label-for="corporate_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Corporate Name"
                    rules="required"
                  >
                    <b-form-input
                      id="corporate_name"
                      v-model="dataInput.name"
                      placeholder="Corporate Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Phone Number"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone Number"
                    rules="required"
                  >
                    <b-input-group :prepend="dataInput.phone_code">
                      <b-form-input
                        id="phone"
                        v-model="dataInput.phone"
                        placeholder="Phone"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Telephone Number"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Telephone Number"
                    rules="required"
                  >
                    <b-input-group :prepend="dataInput.telephone_code">
                      <b-form-input
                        id="phone"
                        v-model="dataInput.telephone"
                        placeholder="Telephone"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Contact Name"
                  label-for="contact_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Contact Name"
                    rules="required"
                  >
                    <b-form-input
                      id="contact_name"
                      v-model="dataInput.contact_name"
                      placeholder="Contact Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="NPWP"
                  label-for="npwp"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NPWP"
                    rules="required"
                  >
                    <b-form-input
                      id="npwp"
                      v-model="dataInput.npwp"
                      placeholder="NPWP"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Website"
                  label-for="website"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Website"
                    rules="required"
                  >
                    <b-form-input
                      id="website"
                      v-model="dataInput.website"
                      placeholder="Website"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Commerce Group"
                  label-for="commerce_group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Commerce Group"
                    rules="required"
                  >
                    <b-form-input
                      id="commerce_group"
                      v-model="dataInput.commerce_group"
                      placeholder="Commerce Group"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="dataInput.description"
                      placeholder="Description"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Max Member"
                  label-for="max_member"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Max Member"
                    rules="required"
                  >
                    <b-form-input
                      id="max_member"
                      v-model="dataInput.capacity"
                      type="number"
                      placeholder="Max Member"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="submit"
                >
                  Edit
                </b-button>
                <b-button
                  variant="secondary"
                  class="ml-1"
                  @click="gotoCorporate"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol,
  BFormGroup, BFormInput, BForm,
  BFormTextarea, BInputGroup,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BInputGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataInput: {
        name: null,
        contact_name: null,
        phone: null,
        phone_code: null,
        telephone: null,
        telephone_code: null,
        website: null,
        npwp: null,
        description: null,
        capacity: null,
      },
    }
  },
  created() {
    this.checkAuth()
  },
  mounted() {
    this.getCorporateData()
  },
  methods: {
    async getCorporateData() {
      const { id } = this.$route.params
      const cid = localStorage.getItem('client_id')

      const dataAPI = await this.$axios.get(`${cid}/corporate/${id}`)
      const dataCorp = dataAPI.data.data

      this.dataInput.name = dataCorp.corporate.corporate_name
      this.dataInput.contact_name = dataCorp.corporate.contact_name
      this.dataInput.phone = dataCorp.corporate.corporate_phone
      this.dataInput.phone_code = dataCorp.corporate.corporate_phonecode
      this.dataInput.telephone = dataCorp.corporate.corporate_telephone
      this.dataInput.telephone_code = dataCorp.corporate.corporate_telephonecode
      this.dataInput.website = dataCorp.corporate.corporate_website
      this.dataInput.npwp = dataCorp.corporate.corporate_npwp
      this.dataInput.commerce_group = dataCorp.corporate.commerce_group
      this.dataInput.description = dataCorp.corporate.corporate_description
      this.dataInput.capacity = dataCorp.corporate.corporate_total_member
    },

    submit() {
      this.$refs.corporateEdit.validate().then(success => {
        if (success) {
          const { id } = this.$route.params
          const cid = localStorage.getItem('client_id')
          const data = this.dataInput

          const formData = {
            name: data.name,
            contact_name: data.contact_name,
            phonecode: data.phone_code,
            phone: data.phone,
            telephonecode: data.telephone_code,
            telephone: data.telephone,
            website: data.website,
            npwp: data.npwp,
            description: data.description,
            commerce_group: data.commerce_group,
            capacity: data.capacity,
          }

          this.$axios.put(`${cid}/corporate/${id}`, formData)
            .then(res => {
              if (res.data.status) {
                // Success code
                this.$bvToast.toast('Success', {
                  title: 'Edit data coprorate',
                  variant: 'success',
                  solid: true,
                  toaster: 'b-toaster-bottom-right',
                  autoHideDelay: 3000,
                })

                setTimeout(() => {
                  this.$router.push({ path: '/user/corporate' })
                }, 3000)
              } else {
                this.$bvToast.toast(res.data.message, {
                  title: 'Edit data coprorate',
                  variant: 'danger',
                  solid: true,
                  toaster: 'b-toaster-bottom-right',
                  autoHideDelay: 3000,
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    gotoCorporate() {
      this.$router.push({ path: '/user/corporate' })
    },
  },
}
</script>
